import React from "react";

import Layout from "../components/layout";
import Banner from "../components/banner";

import Image from "../assets/img/404-palety.png";

const NotFound = () => {
  const lang = "pl";

  return (
    <Layout
      seoTitle="Nie znaleziono strony"
      lang={lang}
      translationEN=""
      translationDE=""
    >
      <div className="homepage">
        <Banner
          type="404"
          title="404"
          subtitle="Nie znaleziono strony"
          image={Image}
          lang={lang}
        />
      </div>
    </Layout>
  );
};

export default NotFound;
